import DataS from "./data.s";

export default class AppS extends DataS {

    create(data) {
        return this.post('/applications', data)
    }

    init(EMPLOYEE_ID, TOTAL_HOURS, SESSION_APPLICATION_ID, SUBJECT_CONSTRAINT_ID) {
        return this.post('/applications/init', {
            EMPLOYEE_ID,
            TOTAL_HOURS,
            SESSION_APPLICATION_ID,
            SUBJECT_CONSTRAINT_ID
        })
    }

    current(STUDENT_ID, SESSION_APPLICATION_ID) {
        return this.get('/applications/current/' + STUDENT_ID + '/' + SESSION_APPLICATION_ID)
    }

    gets(STUDENT_ID) {
        return this.get('/applications/' + STUDENT_ID)
    }

    getAll(STUDENT_ID) {
        return this.get('/applications/all/' + STUDENT_ID)
    }
    getAffectations(STUDENT_ID) {
        return this.get('/applications/affectations/' + STUDENT_ID)
    }

    close(id) {
        return this.get('/applications/close/' + id)
    }
}
