import DataS from "./data.s";
import CookieH from "../helpers/cookie.h";

export default class QuestionFormS extends DataS {
  loadQuestions(form) {
    return this.get('/question-forms/' + form)
  }

  loadAllQuestions(form) {
    return this.get('/question-forms')
  }

  submit(input, EVALUATION_SESSION_ID,STUDENT_EVALUATION_SESSION) {
    return this.post('/question-forms/submit', {
      STUDENT_EVALUATION_SESSION,
      input,
      EVALUATION_SESSION_ID
    })
  }

  newQuestion(data) {
    return this.post('/question-forms', data)
  }
}
