import DataS from "./data.s";

export default class CandidateS extends DataS {

  create(candidate) {
    return this.post('/candidate', candidate)
  }

  authEmail(email) {
    return this.post('/candidate/auth-email', {email})
  }
}
